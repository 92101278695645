import React from 'react'
import PropTypes from 'prop-types'
import { navigate } from 'gatsby'

import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes, faSearch } from '@fortawesome/free-solid-svg-icons'

import isMeaningfulString from "utils/isMeaningfulString/isMeaningfulString";
import objToQueryString from "utils/objToQueryString/objToQueryString";
import parseUrlQuery from "utils/parseUrlQuery/parseUrlQuery";
import capitalize from "utils/capitalize/capitalize";
import setDocumentTitle from "utils/setDocumentTitle/setDocumentTitle";
import AUTO_SUGGEST_ISSUES from "utils/graphql/autoSuggest/issues/issues";
import AUTO_SUGGEST_YEAR from "utils/graphql/autoSuggest/year/year";
import getNetworkQuery from "utils/graphql/network/network";
import { withApolloClient } from "utils/graphqlSetup"

import AutoSuggestion from "components/autoSuggestion/AutoSuggestion.js";
import NetworkContainer from "components/viz/networkContainer/NetworkContainer";
import SocialMedia from "components/socialMedia/SocialMedia"

import "./dataVizNetwork.scss"

const INITIAL_NETWORK_DATA_STATE = {
  errorMessage: "",
  issueCode: "",
  issueCodeDescription: "",
  year: "",

  networkData: {
    nodes: [
      {node_id: 1, entity_name: "Pol A", entity_details: {politician_id: 1}},
      {node_id: 2, entity_name: "Pol B", entity_details: {politician_id: 2}},
      {node_id: 3, entity_name: "Pol C", entity_details: {politician_id: 3}},
      {node_id: 4, entity_name: "Pol D", entity_details: {politician_id: 4}},
      {node_id: 5, entity_name: "Pol E", entity_details: {politician_id: 5}},

      {node_id: 100, entity_name: "Company 1", entity_details: {}},
      {node_id: 101, entity_name: "Company 2", entity_details: {}},
      {node_id: 102, entity_name: "Company 3", entity_details: {}},
      {node_id: 103, entity_name: "Company 4", entity_details: {}},
    ],
    edges: [
      {edge_id: 1, node_ids: [1, 100], entity_details: {n_issues: 2}},
      {edge_id: 2, node_ids: [1, 102], entity_details: {n_issues: 7}},
      {edge_id: 3, node_ids: [2, 101], entity_details: {n_issues: 12}},
      {edge_id: 4, node_ids: [3, 102], entity_details: {n_issues: 3}},
      {edge_id: 5, node_ids: [4, 100], entity_details: {n_issues: 14}},
      {edge_id: 6, node_ids: [4, 101], entity_details: {n_issues: 18}},
      {edge_id: 6, node_ids: [4, 102], entity_details: {n_issues: 9}},
      {edge_id: 7, node_ids: [5, 103], entity_details: {n_issues: 1}},
    ]
  },
  requestStatus: "",
}

class DataVizNetwork extends React.Component {
  constructor(props) {
    super(props)

    setDocumentTitle("Political Networks")

    this.ISSUES = {
      getQuery: AUTO_SUGGEST_ISSUES,
      processResponse: response => {
        if (!response.data || !response.data.topIssueCode) { throw Error("Response error"); }
        return response.data.topIssueCode;
      },
      getSuggestionString: suggestion => { return suggestion.candidateIssueCode.issueCodeDescription; },
      clickSuggestion: (self, suggestion) => {
        this.setState({
          issueCode: suggestion.candidateIssueCode.issueCode,
          issueCodeDescription: suggestion.candidateIssueCode.issueCodeDescription,
        });
        self.clearSuggestions(); //clear the suggestions
      },
    }

    this.YEAR = {
      getQuery: AUTO_SUGGEST_YEAR,
      processResponse: response => {
        if (!response.data || !response.data.topLobbyingYear) { throw Error("Response error"); }
        return response.data.topLobbyingYear;
      },
      getSuggestionString: suggestion => { return suggestion.lobbyingYear.toString(); },
      clickSuggestion: (self, suggestion) => {
        this.setState({
          year: suggestion.lobbyingYear,
        });
        self.clearSuggestions(); //clear the suggestions
      },
    }



    const query = parseUrlQuery(window.location.search.substring(1)); //get the query in the url if any

    this.state = {
      ...INITIAL_NETWORK_DATA_STATE,

      issueCode: query.issueCode || "",
      issueCodeDescription: query.issueCodeDescription || "",
      year: query.year || "",
    };
  }

  componentDidMount() {
    const query = parseUrlQuery(window.location.search.substring(1)); //get the query in the url if any
    this.getNetworkData(query);
  }

  submit = e => {
    e.preventDefault();

    let query = {vizTab: this.props.tab};

    if(this.state.issueCode.trim().length>0 && this.state.issueCodeDescription.trim().length>0) { query.issueCode = this.state.issueCode.trim(); }
    if(this.state.issueCodeDescription.trim().length>0) { query.issueCodeDescription = this.state.issueCodeDescription.trim(); }
    if(!isNaN(parseInt(this.state.year))) { query.year = this.state.year; } //if the year is a valid integer, add it to the query

    navigate(window.location.pathname+"?"+objToQueryString(query)); //change the URL

    this.getNetworkData(query); //get data
  }

  getNetworkData = query => {
    const year = parseInt(query.year);

    const yearIsValid = !isNaN(year)
    const issueCodeIsValid = isMeaningfulString(query.issueCode)

    if(!yearIsValid && issueCodeIsValid) { //if the year is invalid AND there is an issue
      this.setState({
        errorMessage: "Please enter a valid year"
      });
    }
    else if(yearIsValid && !issueCodeIsValid) { //if the year is valid AND the issue is invalid
      this.setState({
        errorMessage: "Please enter a valid issue"
      });
    }
    else if(yearIsValid && issueCodeIsValid) { //if both the year and issue are valid, make the request
      this.setState({
        errorMessage: "",
        requestStatus: "loading"
      });

      this.props.client.query({
        query: getNetworkQuery(query.issueCode, year)
      })
      .then((response) => {
        if (!response.data) { throw Error("The response had no data"); }
        return response.data;
      }).then((data) => {
        console.log("network data",data.vizTop50FirmsTop50Politicians);
        data.vizTop50FirmsTop50Politicians.nodes.forEach(node => node.entity_name=capitalize(node.entity_name))
        this.setState({
          networkData: data.vizTop50FirmsTop50Politicians,
          requestStatus: "done"
        });
      }).catch((error) => {
        this.setState({
          requestStatus: "error"
        });
        console.log(error);
      });
    }
    else { //else both fields are empty, use the dummy data
      this.clearForm()
    }
  }

  clearForm = e => {
    this.setState({
      ...INITIAL_NETWORK_DATA_STATE
    });
  }

  getViz = () => {
    if(this.state.requestStatus === "error") {
      return (
        <div>There was an error with the request. Please try again another time!</div>
      )
    }
    if(this.state.requestStatus === "loading") {
      return (
        <div>Loading...</div>
      )
    }
    else if(this.state.errorMessage) {
      return (
        <div>{this.state.errorMessage}</div>
      )
    }

    return (
      <NetworkContainer
        fullLegend={true}
        networkData={this.state.networkData}
        pageHeight={this.props.pageHeight}
        requestStatus={this.state.requestStatus}
      />
    )
  }


  render() {
    return (
      <div id="dataVizNetworkContainer">
        <h2 id="dataVizNetworkHeading">
          Political Networks
        </h2>

        <div id="dataVizNetworkContent">
          <div id="dataVizNetworkForm">
            <Form.Group>
              <Form.Label>Year</Form.Label>
              <AutoSuggestion
                minInputLength={1}
                maxSuggestions={5}
                placeholder={"e.g. 2014"}
                clickSuggestion={this.YEAR.clickSuggestion}
                processResponse={this.YEAR.processResponse}
                getSuggestionString={this.YEAR.getSuggestionString}
                getQuery={this.YEAR.getQuery}

                type="number"
                parentInput={this.state.year}
                parentChangeInput={input => this.setState({year: input})}
              />
            </Form.Group>

            <br/>

            <Form.Group>
              <Form.Label>Report Issues</Form.Label>
              <AutoSuggestion
                minInputLength={1}
                maxSuggestions={5}
                placeholder={"e.g. Taxation"}
                clickSuggestion={this.ISSUES.clickSuggestion}
                processResponse={this.ISSUES.processResponse}
                getSuggestionString={this.ISSUES.getSuggestionString}
                getQuery={this.ISSUES.getQuery}

                parentInput={this.state.issueCodeDescription}
                parentChangeInput={input => this.setState({issueCodeDescription: input})}
              />
            </Form.Group>

            <br/>

            <div>
              <Button type="submit" onClick={this.submit} style={{float: "right"}}>Search <FontAwesomeIcon icon={faSearch}/></Button>

              <Button variant="secondary" onClick={this.clearForm}><FontAwesomeIcon icon={faTimes}/> Clear</Button>
            </div>

            <br/>

            <SocialMedia/>
          </div>

          <div id="dataVizNetworkViz">
            {this.getViz()}
          </div>
        </div>
      </div>
    )
  }
}

DataVizNetwork.propTypes = {
  client: PropTypes.object.isRequired,
  pageHeight: PropTypes.number.isRequired,
  tab: PropTypes.string.isRequired,
}

export default withApolloClient(DataVizNetwork)

import React, { useState } from 'react';

import Dropdown from 'react-bootstrap/Dropdown';
import { faUserCircle, faBars } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image';

import ROUTES from "utils/routes"
import SignOutButton from 'components/firebase/SignOut';
import { useAuthentication } from 'components/firebase/AuthUserContext';

import "./navbar.scss";

export const NAVBAR_HEIGHT = 40;

export default function NavBar() {
  const [showMenu, setShowMenu] = useState<boolean>(false)

  const onClick = () => setShowMenu(!showMenu);

  return (
    <div>
      <nav id="navbar">
        <div id="navbar-primary-row" >
          <Link id="logo-container" to={ROUTES.LANDING} >
            <StaticImage src="../../images/refactor/LobbyViewLogo.svg" alt="logo" style={{width: "40px"}}/>
            <span id="brand"><Link to={ROUTES.LANDING}><b>LOBBY</b>VIEW</Link></span>
          </Link>

          <div id="routing-container">
            <span id="desktop-menu">
              <Link to={ROUTES.ABOUT}>About</Link>
              <Link to={ROUTES.DATA_DOWNLOAD}>Data Download</Link>
              <Link to={ROUTES.QUERY}>Advanced Search</Link>
              <Link to={ROUTES.DATA_VIZ_PAGE}>Data Visualizations</Link>
            </span>

            <CustomDropdown />

            <FontAwesomeIcon id="menu-icon" icon={faBars} onClick={onClick}/>
          </div>
        </div>

        <div id="collapsable-menu" className={showMenu ? 'show' : 'hide'}>
          <Link to={ROUTES.ABOUT}>About</Link>
          <Link to={ROUTES.DATA_DOWNLOAD}>Data Download</Link>
          <Link to={ROUTES.QUERY}>Advanced Search</Link>
          <Link to={ROUTES.DATA_VIZ_PAGE}>Data Visualizations</Link>
        </div>
      </nav>
    </div>
  );
}

const CustomDropdown = () => {
  const authUser = useAuthentication()

  return (
    <Dropdown id="login-container">
      <Dropdown.Toggle as={CustomToggle}></Dropdown.Toggle>

      {authUser ? <NavigationAuth /> : <NavigationNonAuth />}
    </Dropdown>
  )
}

const CustomToggle = React.forwardRef(({ onClick }, ref) => (
  <span
    ref={ref}
    onClick={e => {
      e.preventDefault();
      onClick(e);
    }}
  >
    <FontAwesomeIcon  id="account-icon" icon={faUserCircle}/>
  </span>
));

const NavigationAuth = () => (
  <Dropdown.Menu className="login-menu">
    <Dropdown.Item><Link to={ROUTES.ACCOUNT}>Account</Link></Dropdown.Item>
    <Dropdown.Item><SignOutButton/></Dropdown.Item>
  </Dropdown.Menu>
);

const NavigationNonAuth = () => (
  <Dropdown.Menu className="login-menu">
    <Dropdown.Item><Link to={ROUTES.SIGN_IN}>Sign In</Link></Dropdown.Item>
  </Dropdown.Menu>
);
